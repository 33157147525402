<template>
  <base-modal :title="title || $t('selectevents')" @close="$ionic.modalController.dismiss()">
    <ion-toolbar slot="header">
      <ion-item>
        <ion-label>{{ $t('filtereventsby') }}</ion-label>
        <ion-button slot="end" @click="showPopoverFilter">{{ selectedFilter.title }}</ion-button>
      </ion-item>
    </ion-toolbar>
    <ion-content>
      <Refresher @refresh="doRefresh($event)" />
      <List
        :header="$t('events')"
        :not-found-message="$t('noeventsfound')"
        :loading="$apollo.loading && !getAllFacilityEvents "
        :loading-more="loadingMore"
        :disabled="$apollo.loading || $apollo.debouncing"
        @loadMore="loadMore($event, 'getAllFacilityEvents')">
        <ion-radio-group v-if="filtered && filtered.length">
          <ion-item
            button
            v-for="item in filtered"
            :key="item.id"
            :disabled="isDisabled(item)"
            @click="selectItem(item, $event)">
            <ion-checkbox
              slot="start"
              :checked="isSelected(item)"
              style="pointer-events: none"
            />
            <EventItem :event="item" :show-facility="true" />
          </ion-item>
        </ion-radio-group>
      </List>
    </ion-content>

    <ion-footer>
      <ion-item v-if="selected.length">
        <ion-text>
          <span>{{ $t('selecteditemscount', {count: selected.length}) }}</span>
          <span class="ml-2" v-if="itemsHidden.length"><em>({{ $t('hiddencount',{count: itemsHidden.length}) }})</em></span>
        </ion-text>
        <ion-button slot="end" @click="clearSelection">{{ $t('clear') }}</ion-button>
      </ion-item>
      <ion-text class="ion-padding" color="warning" v-if="limitReached">
        {{ $t('selectionlimitreached', {count: this.limit}) }}
      </ion-text>
      <ion-button :disabled="!selected.length" @click="submit" expand="full" color="moto">{{ buttonLabel || $t('ok') }}</ion-button>
    </ion-footer>
  </base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue'
import { GET_ALL_FACILITY_EVENTS } from "@/graphql/queries";
import { infiniteScrollMixin } from "@/mixins";
import { defaultPaginationConfig } from "@/configs";
import EventItem from "@/components/items/EventItem";
import Popover from "@/components/Popover";

export default {
  props: {
    title: {
      type: String
    },
    facility_id: {
      value: [Number, String],
    },
    onSubmit: {
      type: Function
    },
    excludedIds: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: null
    },
    buttonLabel: {
      type: String,
    }
  },
  components: {
    BaseModal,
    EventItem,
  },
  mixins: [infiniteScrollMixin],
  data() {
    const filterOptions =[
      {
        title: this.$t('allevents'),
        id: 'ALL',
        system_name: 'ALL',
      },
      {
        title: this.$t('heldevents'),
        id: 'HELD',
        system_name: 'HELD',
      },
      {
        title: this.$t('plannedevents'),
        id: 'PLANNED',
        system_name: 'PLANNED',
      },
      {
        title: this.$t('cancelledevents'),
        id: 'CANCELLED',
        system_name: 'CANCELLED',
      },
    ];
    return {
      filterOptions,
      selectedFilter: filterOptions[0],
      selected: [],
    }
  },
  computed: {
    events() {
      if (!this.getAllFacilityEvents || !this.getAllFacilityEvents.data) {
        return [];
      }
      return this.getAllFacilityEvents.data;
    },
    itemsHidden() {
      const visibleItemIds = this.filtered.map( item => item.id );
      return this.selected.filter( item => !visibleItemIds.includes(item.id))
    },
    filtered() {
      return this.events;
    },
    limitReached() {
      if (this.limit === null) {
        return false;
      }
      return this.selected.length >= this.limit;
    }
  },
  apollo: {
    getAllFacilityEvents: {
      query: GET_ALL_FACILITY_EVENTS,
      fetchPolicy: 'network-only',
      debounce: 250,
      variables() {
        return {
          ...defaultPaginationConfig,
          facility_id: this.facility_id,
          filter_by: this.selectedFilter.system_name,
        }
      },
    }
  },
  methods: {
    isDisabled(item) {
      return this.isExcluded(item) || (!this.isSelected(item) && this.limitReached);
    },
    isExcluded(item) {
      return this.excludedIds.find( (i) => item.id === i )
    },
    clearSelection() {
      this.selected.splice(0, this.selected.length);
    },
    async doRefresh(event) {
      this.resetInfiniteScroll();
      await this.$apollo.queries.getAllFacilityEvents.refetch()
      event.target.complete();
    },
    // eslint-disable-next-line no-unused-vars
    async selectItem(item, $event) {
      const idx = this.selected.findIndex( (event) => event.id == item.id );

      if (idx === -1) {
        if (this.limitReached) {
          return;
        }
        this.selected.push(item);
      } else {
        this.selected.splice(idx, 1);
      }
    },
    isSelected(item) {
      return this.selected.findIndex( (event) => event.id == item.id ) !== -1;
    },
    submit() {
      this.onSubmit(this.selected);
      this.$ionic.modalController.dismiss();
    },
    async showPopoverFilter(event) {
      const popup = await this.$ionic.popoverController.create({
        component: Popover,
        componentProps: {
          parent: this,
          propsData: {
            title: this.$t('filtereventsby'),
            items: this.filterOptions,
            selected: this.selectedFilter.system_name,
            dismiss: () => {
              popup.dismiss()
            },
            selectItem: (item) => {
              this.selectedFilter = item;
              this.resetInfiniteScroll();
            }
          }
        },
        translucent: false,
        event
      });
      await popup.present();
    }
  }
}
</script>